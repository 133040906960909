@import '../../styles/helpers/index.scss';

.cta {
  margin: 140px 0 0;
  padding: 175px 0 140px;
  position: relative;

  background-image: url('../../static/cta_bg.webp');
  background-position: 0 20%;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 0;

  @media screen and (max-width: $tablet-sm) {
    background-image: url('../../static/cta_bg_mob.webp');
    margin: 70px 0 0;
    background-position: 40% 0;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;

    background: linear-gradient(0deg, rgba(0, 0, 68, 0.4), rgba(0, 0, 68, 0.4));
    position: absolute;
    top: 0;
    z-index: -1;
    user-select: none;
  }

  .section-title {
    color: #fff;
  }

  .button {
    background-color: map-get($colors, 'orange');

    &:hover {
      background-color: rgba(map-get($colors, 'orange'), 0.8);
    }
  }

  &__links {
    @include align-center;
    gap: 15px;

    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    span {
      font-family: 'Fjalla One', sans-serif;
      font-size: 24px;
      line-height: 200%;

      // @media screen and (max-width: $tablet-sm) {
      //   font-size: 16px;
      // }

      a {
        color: #fff;
        position: relative;

        &::before {
          content: '';
          width: 0;
          height: 2px;
          background-color: #fff;

          transition: width 0.3s ease;

          position: absolute;
          bottom: 0;
        }

        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
}
