@import '../../styles/helpers/index.scss';

.reviews {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    &--card {
      min-height: 540px;
      margin: 20px 10px;
      padding: 45px 30px 0;

      background: #fff;
      border-radius: 10px;

      font-family: 'Open Sans', sans-serif;
      box-shadow: 0 0 6px 5px rgb(7 7 7 / 12%);

      header {
        @include align-center;
        justify-content: space-between;
      }

      &__source {
        @include align-center;
        justify-content: center;

        width: 60px;
        height: 60px;

        border-radius: 50%;
        border: 2px solid map-get($colors, 'blue');
      }

      &__meta {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;

        p {
          color: #ccc;
          font-size: 16px;
          line-height: 155%;
        }
      }

      .name {
        margin: 30px 0;
        font-family: 'Fjalla One', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
      }

      .text {
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        height: 300px;
        line-height: 150%;
        overflow: scroll;
      }
    }
  }

  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 60px;
      height: 60px;

      background: #fff;
      border: 1px solid map-get($colors, 'orange');
      border-radius: 50%;

      @include align-center;
      justify-content: center;

      position: absolute;
      right: 0;
      top: -80px;

      cursor: pointer;

      &::before {
        content: '';
        width: 33px;
        height: 16px;

        background-image: url('https://bigcityhomeservice.com/wp-content/uploads/2022/08/Arrow-2.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @media screen and (max-width: $tablet-sm) {
        top: -30px;
        width: 45px;
        height: 45px;
      }
    }

    .slick-next {
      right: 20px;

      @media screen and (max-width: $tablet-sm) {
        right: 0;
      }
    }

    .slick-prev {
      right: 100px;
      left: unset;

      &::before {
        transform: translate(-50%, -50%) rotate(180deg);
      }

      @media screen and (max-width: $tablet-sm) {
        right: auto;
        left: 0;
      }
    }
  }
}
