@import './helpers/variables.scss';
@import './helpers/mixins.scss';

html {
  height: 100% !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Open Sans', sans-serif;

  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  #terms-of-use main,
  #privacy-policy main,
  #accessibility-statement main {
    padding: 70px 0 0;
  }

  main {
    flex: 1 0 auto;
    padding: 140px 0 0;
    transition: all 0.3s ease;

    // @media screen and (max-width: $tablet-sm) {
    // }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }
}

.button {
  display: block;
  padding: 14px 0;

  font-family: 'Fjalla One', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;

  border-radius: 60px;

  color: #fff;

  width: 175px;

  transition: background-color 0.3s ease;
}

.section-title {
  @include align-center;
  position: relative;
  margin: 0 0 60px;
  padding-left: 40px;

  color: map-get($colors, 'text');
  font-family: 'Fjalla One', sans-serif;
  font-weight: 700;
  font-size: 52px;
  line-height: 1;
  text-transform: uppercase;

  @media screen and (max-width: $tablet-sm) {
    font-size: 32px;
    line-height: 40px;
  }

  &::before {
    content: '';
    background-color: map-get($colors, 'orange');
    height: 100%;
    left: 0;
    position: absolute;
    width: 10px;
  }
}

.thank-you {
  padding: 120px 0;
  @include align-center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  max-width: 650px;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: $tablet-sm) {
    padding: 60px 15px;
    max-width: unset;
  }

  .title {
    font-size: 36px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 28px;
    font-weight: bold;
  }

  p {
    font-size: 18px;
  }
}
