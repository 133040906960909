@import '../../styles/helpers/index.scss';

.areas {
  margin: 140px 0 0;
  padding: 115px 0;
  position: relative;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1 !important;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &::before {
    content: '';

    background-image: url('../../static/decor_blink_white.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    width: 125px;
    height: 78px;
    position: absolute;
    right: 10%;
  }

  &::after {
    content: '';

    background-color: #000;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0.5;
    z-index: -1;
  }

  .section-title {
    color: #fff;

    position: relative;
    display: inline-block;

    &::after {
      content: '';
      background-image: url('../../static/subtitle-span_white.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 10px;
      width: 146px;
      position: absolute;
      top: 50%;
      right: -170px;
      transform: translateY(-50%);

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }
  }

  ul {
    color: #fff;
    columns: 3;
    padding: 0 30px;

    @media screen and (max-width: $tablet-sm) {
      columns: 2;
    }

    li {
      position: relative;
      @include align-center;

      font-family: 'Open Sans', sans-serif;

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }

      &::before {
        content: '';

        background-image: url('../../static/list-type.png');
        left: -30px;
        position: absolute;
        width: 13px;
        height: 10px;
      }
    }
  }
}
