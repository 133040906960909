@import '../../styles/helpers/index.scss';

.satisfaction {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      background-image: url('../../static/subtitle-span.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 10px;
      width: 146px;
      position: absolute;
      top: 50%;
      right: -170px;
      transform: translateY(-50%);

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }
  }

  &__list {
    @include grid($columns: repeat(3, 1fr));

    & > div {
      @include align-center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 20px rgb(7 7 7 / 12%);
      padding: 45px 0;

      @include scale;
    }
  }
}
