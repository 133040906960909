@import '../../styles/helpers/index.scss';

.hero {
  &__inner {
    font-family: 'Fjalla One', sans-serif;
    max-width: 1440px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    gap: 50px;

    position: relative;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      padding: 0;
      gap: 30px;
      max-width: unset;
      width: auto;
    }

    &::before {
      content: '';
      background: rgba(251, 102, 22, 0.1);
      border-radius: 50%;

      height: 1200px;
      width: 1200px;

      position: absolute;
      top: -350px;
      left: 700px;
      z-index: -1;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    & > div {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
        width: unset;
      }
    }
  }

  &__title {
    @include align-center;
    flex-direction: column;
    justify-content: center;

    padding: 50px 0 30px;

    @media screen and (max-width: $tablet-sm) {
      padding: 30px 15px 0;
      text-align: center;
    }

    h1 {
      font-size: 65px;
      line-height: 130%;
      text-transform: uppercase;

      position: relative;

      @media screen and (max-width: $tablet-sm) {
        font-size: 46px;
        line-height: 130%;
      }

      &::before {
        content: '';
        background-image: url('../../static/subtitle-span.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 10px;
        width: 146px;

        position: absolute;
        top: -20px;
        left: 0;

        @media screen and (max-width: $tablet-sm) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    p {
      font-size: 40px;
      margin: 15px 0 0;
    }
  }

  &__badges {
    margin: 20px 0 0;
    @include align-center;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }

    img {
      height: 86px;
      width: auto;
      object-fit: cover;
    }
  }

  &__links {
    margin: 50px 0 0;
    @include align-center;
    gap: 30px;

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
      gap: 15px;
      flex-direction: column;
    }

    .button {
      background-color: map-get($colors, 'blue');

      &:hover {
        background-color: map-get($colors, 'darkblue');
      }
    }

    span {
      font-size: 24px;
      line-height: 200%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 28px;
      }

      a {
        position: relative;
        color: map-get($colors, 'text');

        @media screen and (max-width: $tablet-sm) {
          display: inline-block;
          border: 2px dashed map-get($colors, 'orange');
          padding: 5px;
          line-height: 1.24;
        }

        &::before {
          content: '';
          width: 0;
          height: 2px;
          background-color: map-get($colors, 'text');

          transition: width 0.3s ease;

          position: absolute;
          bottom: 0;
        }

        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  &__rating {
    background-image: url('../../static/hero_bg.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &--inner {
      padding: 30px;
      @include grid(20px, 20px, repeat(2, 1fr), repeat(2, 1fr));

      a {
        padding: 0 15px;
        background-color: rgba(255, 255, 255, 0.7);
        color: map-get($colors, 'text');
        border-radius: 10px;

        @include align-center;
        justify-content: space-around;
        height: 80px;

        font-family: 'Lato', sans-serif;
        font-size: 18px;
        font-weight: bold;
        line-height: 110%;
        text-align: center;

        &:last-of-type {
          grid-area: 2 / 1 / 3 / 3;

          @media screen and (max-width: $tablet-sm) {
            grid-area: unset;
          }

          span {
            display: none;

            @media screen and (max-width: $tablet-sm) {
              display: block;
            }
          }
        }

        span {
          display: block;
          margin: 0 0 10px;
        }
      }
    }
  }
}
