@import '../../styles/helpers/index.scss';

.page-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: box-shadow 0.3s ease;

  &.shadow {
    box-shadow: 0px 5px 15px 0px rgba(#000, 0.5);
  }

  .topline {
    height: 40px;
    background-color: map-get($colors, 'orange');
    color: #fff;
    line-height: 40px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 1rem;
    text-align: right;
    text-transform: uppercase;

    @media screen and (max-width: $tablet-sm) {
      height: 60px;
      line-height: 60px;
    }

    p {
      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    a {
      display: none;
      position: relative;

      color: #fff;
      font-size: 20px;

      @media screen and (max-width: $tablet-sm) {
        display: inline-block;
        font-size: 24px;
        text-decoration: underline !important;
      }

      &::before {
        content: '';
        background-image: url('../../static/phone-icon.svg');
        background-size: cover;
        background-position: center;
        background-size: cover;
        width: 18px;
        height: 18px;

        position: absolute;
        top: 50%;
        left: -25px;
        transform: translateY(-50%);

        @media screen and (max-width: $tablet-sm) {
          width: 25px;
          height: 25px;
          left: -35px;
        }
      }
    }
  }

  .line {
    padding: 10px 0;
    background-color: #fff;

    .container {
      @include align-center;
      justify-content: space-between;
    }
  }

  &__logo {
    display: block;
    max-width: 150px;

    @media screen and (max-width: $tablet-sm) {
      max-width: 110px;
    }

    img {
      object-fit: cover;
    }
  }

  .links {
    @include align-center;
    gap: 40px;

    a {
      &.button {
        background-color: map-get($colors, 'blue');

        &:hover {
          background-color: map-get($colors, 'darkblue');
        }
      }

      &:first-of-type {
        position: relative;
        line-height: 1;
        font-size: 30px;
        font-family: 'Fjalla One', sans-serif;
        color: map-get($colors, 'text');

        @include align-center;

        @media screen and (max-width: $tablet-sm) {
          display: none;
        }

        &::before {
          content: '';
          background-image: url('../../static/phone-icon_orange.svg');
          background-size: cover;
          background-position: center;
          background-size: cover;
          width: 30px;
          height: 30px;

          position: absolute;
          top: 50%;
          left: -45px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
