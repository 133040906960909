@import '../../styles/helpers/index.scss';

.content-block {
  margin: 70px 0 0;
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;

  h2 {
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 30px;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;
    margin: 0 0 15px;
  }

  p {
    font-size: 18px;
    line-height: 120%;

    &:not(:last-of-type) {
      margin: 0 0 15px;
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
    padding-left: 30px;
    margin: 0 0 15px;
  }
}
