@import '../../styles/helpers/index.scss';

.services {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    @include grid($columns: repeat(4, 1fr));

    &--item {
      background-color: map-get($colors, 'blue');
      border-radius: 10px;
      color: #fff;
      padding: 50px 40px 40px;
      text-align: center;

      @include scale;

      .icon {
        margin: 0 auto;
        background-color: #fff;
        border-radius: 50%;
        width: 100px;
        height: 100px;

        @include align-center;
        justify-content: center;
      }

      h4 {
        margin: 30px 0 0;
        font-family: 'Fjalla One', sans-serif;
        font-size: 30px;
        line-height: 130%;
        text-transform: uppercase;
      }
    }
  }
}
