@import '../../styles/helpers/index.scss';

.booking-form {
  padding: 60px 0;
  max-width: 400px;
  margin: 0 auto;

  h4 {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
  }

  form {
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
      width: unset;
    }

    & *::placeholder {
      font-family: 'Open Sans', sans-serif;
      color: map-get($colors, 'text');
    }

    & > * {
      &:not(input[type='submit']) {
        color: map-get($colors, 'text');
        padding: 0 20px;
        line-height: 50px;

        border-radius: 10px;
        border: 1px solid map-get($colors, 'text');
        outline: none;

        font-size: 18px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    input,
    select {
      display: block;
      max-width: 100%;

      height: 50px;
    }

    textarea {
      font-family: 'Open Sans', sans-serif;
      height: 120px !important;
      padding-top: 15px !important;
      line-height: 120% !important;
    }

    .button {
      background-color: map-get($colors, 'orange');
      border: none;
      font-family: 'Open Sans', sans-serif;

      &:hover {
        cursor: pointer;
        background-color: rgba(map-get($colors, 'orange'), 0.8);
      }
    }
  }

  .data-protection-label {
    margin: 15px 0 0;
    @include align-center;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
