@import '../../styles/helpers/index.scss';

.why-we {
  margin: 140px 0 0;
  position: relative;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    display: flex;
    gap: 60px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      &:first-of-type {
        & > p {
          font-family: 'Open Sans', sans-serif;
          font-size: 18px;
          line-height: 155%;
        }
      }
    }
  }

  &__reasons {
    @include grid;
    margin: 70px 0 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 50px 0 0;
    }

    &--card {
      .header {
        @include align-center;
        gap: 40px;

        @media screen and (max-width: $tablet-sm) {
          gap: 20px;
        }

        .icon {
          background-color: map-get($colors, 'orange');
          border-radius: 50%;

          height: 80px;
          width: 80px;

          @include align-center;
          justify-content: center;

          img {
            height: 45px;
            width: 45px;
            object-fit: contain;
          }
        }

        h4 {
          font-family: 'Open Sans', sans-serif;
          font-size: 26px;
          font-weight: 700;
          line-height: 35px;
          text-transform: uppercase;
        }
      }

      p {
        margin: 25px 0 0;
        font-size: 18px;
        line-height: 155%;
      }
    }
  }

  &__form {
    max-width: 400px;
    width: 100%;
    background-color: map-get($colors, 'blue');
    padding: 40px;
    color: #fff;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;

    h4 {
      color: #fff;
      text-align: center;
    }
  }

  form {
    & *::placeholder {
      color: #fff;
    }

    & > * {
      &:not(input[type='submit']) {
        background-color: rgba(#000, 0.3);
        color: #fff;
        border: 1px solid #fff;
      }
    }

    input[type='submit'] {
      font-weight: bold !important;
      &:hover {
        background-color: rgba(map-get($colors, 'orange'), 1) !important;
        box-shadow: 0 0 8px 1px rgba(map-get($colors, 'orange'), 1);
        color: #000 !important;
      }
    }
  }

  .data-protection-label {
    color: #fff;

    svg {
      fill: #fff;
    }
  }
}
