@import '../../styles/helpers/index.scss';

.about {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;
      width: 100%;
      font-size: 18px;
      line-height: 140%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
        width: auto;
      }

      &:last-of-type {
        min-height: 400px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  &__content {
    & > div p {
      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }
}
